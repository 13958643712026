<template>
<div class="block--elevated block--white p-small text--gray column--centered">
  <nuxt-img provider="strapi" :src="`${icon.hash}${icon.ext}`" class="service__icon"/>
  <h2 class="heading-xsmall text--grey">{{ service.title }}</h2>
  <p class="text--center mt-2">{{ service.description }}</p>
  <div class="spacer"></div>
  <div class="text--red">
    <ButtonTextLink class="mt-4 text--red" :to="`/aanbod/${service.slug}`">{{ service.info_button_text || 'Meer info' }}</ButtonTextLink>
  </div>
</div>
</template>

<script lang="ts" setup>
import ServiceIllustration from '~/assets/svg/aanbod_01.svg?component';
import useStrapiHelper from '~~/composables/strapi';

const props = defineProps({
  service: {
    type: Object,
    required: true
  }
});

const { flattenObject } = useStrapiHelper();
const icon = flattenObject(props.service.icon);
</script>

<style lang="scss">
.service__icon {
  min-width: 160px;
  align-self: center;
  width: 60%;
}
</style>
